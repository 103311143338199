@import './elements.css';
@import './tachyons-full.css';

* {
    box-sizing: border-box;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section,
main {
    display: block;
}

body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
p,
button,
img,
input,
form,
select,
aside,
main {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Roboto', arial, sans-serif;
    font-size: 16px;
    line-height: 1.2;
}
.striped--light-gray:nth-child(odd) {
    background-color: #f8f5f3;
}
.hwx-blue {
    color: #004b8d;
}
.hwx-white {
    color: #ffffff;
}

.hwx-white-hover:hover {
    color: #ffffff;
}

.hwx-white-hover:hover .svg {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(327deg)
        brightness(106%) contrast(102%);
}

.hwx-white .svg {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(327deg)
        brightness(106%) contrast(102%);
}

.bg-hwx-white {
    background-color: #ffffff;
}
.hover-bg-hwx-white:hover {
    background-color: #ffffff;
}

.hover-bg-hwx-blue:hover {
    background-color: #004b8d;
}

.hover-hwx-blue:hover {
    color: #004b8d;
}

.hover-hwx-blue:hover .svg {
    filter: invert(16%) sepia(47%) saturate(5256%) hue-rotate(194deg)
        brightness(93%) contrast(103%);
}

.bg-hwx-grey {
    background-color: #676767;
}

.hover-bg-hwx-light-grey:hover {
    background-color: #f8f5f3;
}

.bg-hwx-light-grey {
    background-color: #f8f5f3;
}

.bg-hwx-blue {
    background-color: #004b8d;
}
.b--blue-90 {
    border-color: rgba(0, 75, 141, 0.9);
}
.b--blue-80 {
    border-color: rgba(0, 75, 141, 0.8);
}
.b--blue-70 {
    border-color: rgba(0, 75, 141, 0.7);
}
.b--blue-60 {
    border-color: rgba(0, 75, 141, 0.6);
}
.b--blue-50 {
    border-color: rgba(0, 75, 141, 0.5);
}
.b--blue-40 {
    border-color: rgba(0, 75, 141, 0.4);
}
.b--blue-30 {
    border-color: rgba(0, 75, 141, 0.3);
}
.b--blue-20 {
    border-color: rgba(0, 75, 141, 0.2);
}
.b--blue-10 {
    border-color: rgba(0, 75, 141, 0.1);
}
.b--blue-05 {
    border-color: rgba(0, 75, 141, 0.05);
}
.b--blue-025 {
    border-color: rgba(0, 75, 141, 0.025);
}
.b--blue-0125 {
    border-color: rgba(0, 75, 141, 0.0125);
}

.hwx-black {
    color: #333333;
}
.hwx-grey {
    color: #a4a29f;
}
.hwx-dark-grey {
    color: #5a5555;
}

.hwx-grey-border {
    border-color: #a4a29f;
}
.hwx-red-border {
    border-color: #b42828;
}

.hwx-orange-border {
    border-color: #f5842b;
}
.hwx-blue {
    color: #004b8d;
}

.hwx-blue .svg {
    filter: invert(16%) sepia(47%) saturate(5256%) hue-rotate(194deg)
        brightness(93%) contrast(103%);
}

.hwx-blue-border {
    border-color: #004b8d;
}

.hwx-blue-hover:hover {
    color: #004b8d;
}
.hwx-blue-bg-hover:hover {
    background: #edf5fc;
    border-radius: 0.25rem;
}

.hwx-green {
    color: #4b9522;
}
.hwx-red {
    color: #b42828;
}

.hwx-magenta {
    color: #ff00ff;
}

.hwx-orange {
    color: #f5842b;
}
.hwx-light-green {
    color: #2cb195;
}
.hwx-gold {
    color: #ffd700;
}
.hwx-bluelight {
    color: rgb(0, 117, 219);
}
/* https://codepen.io/sosuke/pen/Pjoqqp */
.hwx-light-green .svg {
    filter: invert(53%) sepia(96%) saturate(314%) hue-rotate(117deg)
        brightness(91%) contrast(89%);
}

.hwx-orange .svg {
    filter: invert(69%) sepia(70%) saturate(3491%) hue-rotate(342deg)
        brightness(100%) contrast(93%);
}
.hwx-red .svg {
    filter: invert(23%) sepia(77%) saturate(7388%) hue-rotate(344deg)
        brightness(92%) contrast(92%);
}
.hwx-bluelight .svg {
    filter: invert(66%) sepia(98%) saturate(7488%) hue-rotate(195deg)
        brightness(99%) contrast(100%);
}
.hwx-magenta .svg {
    filter: invert(23%) sepia(74%) saturate(6054%) hue-rotate(296deg)
        brightness(116%) contrast(128%);
}
.hwx-green .svg {
    filter: invert(54%) sepia(14%) saturate(2569%) hue-rotate(55deg)
        brightness(87%) contrast(79%);
}

.hwx-gold .svg {
    filter: invert(73%) sepia(66%) saturate(2008%) hue-rotate(358deg)
        brightness(100%) contrast(107%);
}

.hwx-grey .svg {
    filter: invert(39%) sepia(8%) saturate(0%) hue-rotate(272deg)
        brightness(95%) contrast(80%);
}

.hwx-purple .svg {
    filter: invert(27%) sepia(9%) saturate(7262%) hue-rotate(292deg)
        brightness(90%) contrast(92%);
}

.hwx-yellow .svg {
    filter: invert(84%) sepia(52%) saturate(476%) hue-rotate(353deg)
        brightness(109%) contrast(92%);
}

.bg-hwx-green {
    background-color: #4b9522;
}
.bg-hwx-red {
    background-color: #b42828;
}
.bg-hwx-light-red {
    background-color: #fdebed;
}
.bg-hwx-blue {
    background-color: #004b8d;
}
.bg-hwx-light-blue {
    background-color: #dbeeff;
}
a {
    color: #004b8d;
}

img {
    height: auto;
}

/* ikonene i zoom kontrollene syns ikke i google maps. Denne regelen fikser det! */
.gm-style img {
    box-sizing: content-box;
}

.flex-grow {
    flex-grow: 1;
}

.img-align-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.loading-spinner {
    animation: rotation 0.7s infinite linear;
    border: 3px solid rgba(0, 0, 0, 0.5);
    border-top-color: #000;
    border-radius: 50%;
    content: '';
    height: 1rem;
    width: 1rem;
}
.spin {
    animation: rotation 0.7s infinite linear;
}
.form-group {
    padding: 10px;
    border: 2px solid;
    margin: 10px;
    position: relative;
}
.form-group-label {
    position: absolute;
    top: -1px;
    left: 20px;
    background-color: white;
}

.form-group-input {
    border: none;
}

.disabled {
    opacity: 0.3;
}

.text-outline {
    color: #222222;
    -webkit-text-fill-color: #ffffff; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #222222;
}

.top-3 {
    top: 3rem;
}
@keyframes shake {
    0% {
        margin-left: 0rem;
    }
    25% {
        margin-left: 0.5rem;
    }
    75% {
        margin-left: -0.5rem;
    }
    100% {
        margin-left: 0rem;
    }
}

.invalid {
    animation: shake 0.2s ease-in-out 0s 2;
}
.v-center {
    margin-top: auto;
    margin-bottom: auto;
}
input:invalid {
    border: 2px solid #b42828;
}
body {
    --calcite-ui-info: hsl(226, 40%, 66%);
    --calcite-ui-brand: #004b8d;
    --calcite-ui-background: #f8f5f3;
    --calcite-ui-danger: #b42828;
    --calcite-ui-warning: #f5842b;
}
.calcite-meter-inverted {
    --calcite-ui-danger: #35ac46;
    --calcite-ui-success: #e31937;
    --calcite-ui-warning: #f5ee6a;
}
.calcite-meter-thermometer {
    --calcite-ui-danger: #b42828;
    --calcite-ui-warning: #a4a29f;
    --calcite-ui-success: #004b8d;
}
